<template>
  <b-card>
    <!-- form -->
    <div class="d-flex justify-content-between">
      <h2> Delete User Account</h2>
      <span>
        <b-button
          variant="outline-danger"
          class="btn-tour-skip mr-1"
          @click="openConfirmationModal"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
            <span class="sr-only">Loading...</span>
          </div>
          Delete
        </b-button>
      </span>
    </div>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BButton,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      spinner: false,
    }
  },
  methods: {
    openConfirmationModal() {
      this.$swal({
        title: '<span class="font-weight-bolder">Delete User Account</span>',
        icon: 'warning',
        html:
              'Are you sure you want to delete user account!',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-warning ml-1',
          icon: 'primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.spinner = true
          this.sendPasswordUpdate()
        }
      })
    },
    deleteUserAccount() {
      console.log('hello')
    },
    async sendPasswordUpdate() {
      try {
        const response = await this.$axios.delete('/user/delete-user')
        if (response.data.statusCode === 200) {
          await this.$store.dispatch('user/logoutUserLocalOnly')
          await this.$store.dispatch('user/logoutUserWithRedirect')
          this.$swal.fire('SweetAlert2 is working!')
        }
      } catch (error) {
        showToast('Delete User', error.response.data.message, 'danger', 4000)
        this.$swal({
          icon: 'error',
          text: error.response.data.message,
        })
      } finally {
        this.spinner = false
      }
    },
  },
}
</script>
