<template>
  <div
    id="account-setting"
    class="p-2"
  >
    <div class="d-flex align-items-center pb-2">
      <div class="d-flex w-50 breadcrumb-wrapper justify-content-start">
        <BreadCrumb :bread-crumb-items="breadCrumbItems" />
      </div>
    </div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">General</span>
        </template>
        <account-setting-general
          v-if="options.general"
          :general-data="options.general"
        />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon
            icon="LockIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Change Password</span>
        </template>

        <account-setting-password />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template
          #title
        >
          <feather-icon
            icon="TrashIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Delete User</span>
        </template>
        <DeleteUserAccount />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from '@/components/account-setting/AccountSettingGeneral.vue'
import AccountSettingPassword from '@/components/account-setting/AccountSettingPassword.vue'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import DeleteUserAccount from '@/components/account-setting/DeleteUserAccount.vue'
import store from '@/store'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    BreadCrumb,
    DeleteUserAccount,
  },
  async beforeRouteEnter(to, from, next) {
    const isUserAdmin = await store.getters['user/isUserAdmin']
    if (isUserAdmin) {
      next({
        path: '/admin/applications',
      })
      return
    }
    next()
  },
  data() {
    return {
      options: {
        general: {
          email: 'ali@gmail.com',
          fullName: 'ali raza',
          username: 'username',
        },
      },
      breadCrumbItems: [
        { name: 'Home', to: '/', active: false },
        { name: 'Business', to: '/groups-listing', active: false },
        { name: 'Account Settings', to: null, active: true },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
#account-setting{
  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
</style>
